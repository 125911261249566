var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "z-page",
    [
      _c(
        "el-breadcrumb",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { "separator-class": "el-icon-arrow-right" },
        },
        [
          _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
            _vm._v("首页"),
          ]),
          _c("el-breadcrumb-item", { attrs: { to: { path: "/warehouse" } } }, [
            _vm._v("仓储"),
          ]),
          _c("el-breadcrumb-item", [_vm._v("查询")]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("el-row", { attrs: { gutter: 30, span: 24 } }, [
            _c(
              "div",
              { staticClass: "container info-card" },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    staticClass: "search-form",
                    attrs: {
                      inline: true,
                      rules: _vm.validateForm,
                      model: _vm.editForm,
                      "label-width": "90px",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return (() => {}).apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "快递单号" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "快递单号/入仓单号",
                            clearable: "",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.searchInbound.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.editForm.code,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.editForm,
                                "code",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "editForm.code",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "z-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.searchInbound },
                          },
                          [_vm._v("查询")]
                        ),
                        _vm.isWechat
                          ? _c(
                              "z-button",
                              {
                                attrs: { type: "default" },
                                on: { click: _vm.scanBarcode },
                              },
                              [_vm._v("扫码")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    staticClass: "ordering-list",
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.inboundList },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "waybill",
                        label: "入仓单号",
                        width: "150",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "user_code",
                        width: "80",
                        label: "用户编码",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.user_code
                                ? _c(
                                    "el-link",
                                    {
                                      attrs: {
                                        underline: false,
                                        type: "primary",
                                        href:
                                          "/order?user_code=" +
                                          scope.row.user_code,
                                      },
                                    },
                                    [_vm._v(_vm._s(scope.row.user_code))]
                                  )
                                : _c("p", [_vm._v("-")]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "user_role",
                        label: "客户类型",
                        width: "80",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-tag",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: _vm.formatUserRole(
                                      scope.row.user_role
                                    ).type,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatUserRole(scope.row.user_role)
                                        .label
                                    )
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "created", label: "关联订单" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.package_num
                                ? _c(
                                    "el-link",
                                    {
                                      attrs: {
                                        underline: false,
                                        type: "primary",
                                        href:
                                          "/dashboard?logistics_code=" +
                                          scope.row.package_num,
                                      },
                                    },
                                    [_vm._v(_vm._s(scope.row.package_num))]
                                  )
                                : _c("p", [_vm._v("-")]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "scaned",
                        label: "入仓时间",
                        width: "200",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm._f("formatDate")(scope.row.scaned))
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作", fixed: "right", width: "80" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "z-button",
                                {
                                  attrs: {
                                    type: "danger",
                                    size: "mini",
                                    plain: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeInbound(scope)
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-delete" }),
                                  _vm._v("作废"),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }